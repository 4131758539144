// @flow
import { Map, List } from 'immutable';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import debug from 'utils/debug';
import { ACCESS_DENIED_URL } from 'utils/constants';
import { sleep } from 'utils/async';
import { removeInternalFieldsFromObject } from 'utils/object';
import { normalizeVehicleColorForExport } from 'utils/color';
import parseColor from 'parse-color';
import { fetchData } from './net';
import { getHeaders } from './headers';
import { pagination, urls } from '../config';
import makeBulkUpdateRequest from './CommuteOffer/makeBulkUpdateRequest';

const D2 = debug('api:simulations');

export const getSimulations = async (
  page: number,
  orderingParam: boolean,
  search: string,
  projectId: number
) => {
  const offset = pagination.datasets * (page - 1);
  const url = urls.simulationsList(offset, orderingParam, search, projectId);

  const response = await fetchData(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();
  return responseJSON;
};

export const getSimulation = async (id: number) => {
  const url = urls.simulation(id);

  const response = await fetchData(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  const isLive =
    responseJSON.state === 'running' ||
    responseJSON.state === 'created' ||
    responseJSON.state === 'queued';

  const useVehicleLog =
    !responseJSON.isLive ||
    (responseJSON.isLive && !responseJSON.data.use_websocket_for_frontend);

  return { ...responseJSON, isLive, useVehicleLog };
};

export const getBookingsForSimulation = async (id: number) =>
  D2.A.FUNCTION('getBookingsForSimulation', { id }, async () => {
    const url = urls.bookingsForSimulation(id);

    const response = await fetchData(url, {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  });

export const getVehiclesForSimulation = async (id: number) =>
  D2.A.FUNCTION('getVehiclesForSimulation', { id }, async () => {
    const response = await fetchData(urls.vehiclesForSimulation(id), {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  });

export const getNodesForSimulation = async (id: number) =>
  D2.A.FUNCTION('getNodesForSimulation', { id }, async () => {
    const url = urls.nodesForSimulation(id);

    const response = await fetchData(url, {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  });

export const getSimulationVehicleSet = async (ids) => {
  const url = urls.simulationVehicleSet(ids);
  const response = await fetchData(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  const result = await responseJSON.objects.reduce(
    (acc, item) => acc.set(parseInt(item.id, 10), item),
    Map()
  );

  return result;
};

export const getSimulationNodeSet = async (nodes) => {
  const url = urls.simulationNodeSet(nodes);

  const response = await fetchData(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  const result = await responseJSON.objects.reduce(
    (acc, item) => acc.set(parseInt(item.id, 10), item),
    Map()
  );

  return result;
};

export const getData = async (id: number, offset: number, limit: number) => {
  const url = urls.simulationVehicleLog(id, offset, limit);

  const response = await fetchData(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  return responseJSON;
};

export const getSimulationVehicleLog = async (
  id: number,
  offset: number,
  limit: number
) => {
  const url = urls.simulationVehicleLog(id, offset, limit);

  const response = await fetchData(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  const { meta } = responseJSON;

  const requiredNodes = await responseJSON.objects.reduce((acc, item) => {
    item.assigned_nodes.forEach((node) => {
      acc.add(node.id);
    });
    return acc;
  }, new Set());

  const nodes = await getSimulationNodeSet([...requiredNodes]);

  const data = await responseJSON.objects.reduce((memo, item) => {
    const assigned_nodes = item.assigned_nodes.reduce(
      (assignedNodes, assignedNode) => {
        const nodeFromLog = {
          id: assignedNode.id,
          scheduled_ts: assignedNode.scheduled_ts,
        };
        const nodeFromAPI = nodes.get(assignedNode.id);
        if (nodeFromAPI) {
          return assignedNodes.push({
            ...nodeFromAPI,
            ...nodeFromLog,
          });
        }
        return assignedNodes;
      },
      List()
    );
    const newItem = {
      ...item,
      assigned_nodes,
    };

    if (memo.get(newItem.vehicle_id)) {
      return memo.update(newItem.vehicle_id, value => value.push(newItem));
    }

    return memo.set(newItem.vehicle_id, List([newItem]));
  }, Map());

  return {
    meta,
    data,
  };
};

export const addSimulations = async (body: any) => {
  const response = await fetchData(urls.simulations, {
    method: 'POST',
    headers: getHeaders(),
    body,
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  return responseJSON;
};

export const launchSimulation = async (id: number) => {
  const response = await fetchData(urls.simulationLaunch(id), {
    method: 'POST',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return null;
};

export const deleteSimulation = async (id: number) => {
  const response = await fetchData(urls.simulation(id), {
    method: 'DELETE',
    headers: getHeaders(),
  });
  if (!response.ok) {
    throw new Error(response.status);
  }

  return null;
};

export const updateSimulation = async (id: number, body: any) => {
  const response = await fetchData(urls.simulation(id), {
    method: 'PATCH',
    headers: getHeaders(),
    body,
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  return responseJSON;
};

export const addOrUpdateStartAndEndNodesForVehicle = async (
  vehicleId,
  startPoint,
  endPoint
) =>
  D2.A.FUNCTION(
    'addStartAndEndNodesForVehicle',
    { vehicleId, startPoint, endPoint },
    async () => {
      const newNodes = [];
      const deleteNodes = [];
      const newBookingUid = uuidv4();
      // add new startPoint
      if (
        startPoint &&
        !startPoint.delete_resource_uri &&
        !startPoint.resource_uri
      ) {
        newNodes.push({
          ...startPoint,
          node_type: 'point',
          service_time: 0,
          booking_uid: newBookingUid,
          status: 'assigned',
          partial_route_index: 1,
          finalization_type: 'max',
          assigned_vehicle: `/api/v2/vehicle/${vehicleId}`,
        });
      }
      // update startPoint
      if (
        startPoint &&
        !startPoint.delete_resource_uri &&
        startPoint.resource_uri
      ) {
        newNodes.push({
          resource_uri: startPoint.resource_uri,
          lat: startPoint.lat,
          lon: startPoint.lon,
          location_name: startPoint.location_name,
        });
      }
      // delete startPoint
      if (startPoint && startPoint.delete_resource_uri) {
        deleteNodes.push(startPoint.delete_resource_uri);
      }

      // add new endPoint
      if (endPoint && !endPoint.delete_resource_uri && !endPoint.resource_uri) {
        newNodes.push({
          ...endPoint,
          node_type: 'point',
          service_time: 0,
          booking_uid: newBookingUid,
          status: 'assigned',
          partial_route_index: -1,
          finalization_type: 'min',
          assigned_vehicle: `/api/v2/vehicle/${vehicleId}`,
        });
      }
      // update endPoint
      if (endPoint && !endPoint.delete_resource_uri && endPoint.resource_uri) {
        newNodes.push({
          resource_uri: endPoint.resource_uri,
          lat: endPoint.lat,
          lon: endPoint.lon,
          location_name: endPoint.location_name,
        });
      }
      // delete endPoint
      if (endPoint && endPoint.delete_resource_uri) {
        deleteNodes.push(endPoint.delete_resource_uri);
      }

      const response = await fetchData(`${urls.simulationNodeAPI}`, {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify({
          objects: newNodes,
          deleted_objects: deleteNodes,
        }),
        maxAttempts: 1,
      });

      if (!response.ok) {
        throw new Error(response.status);
      }

      const responseJSON = await response.json();
      return responseJSON;
    }
  );

export const addVehicleToSimulation = async (simulationId, vehicle) =>
  D2.A.FUNCTION(
    'addVehicleToSimulation',
    { simulationId, vehicle },
    async () => {
      const response = await fetchData(urls.vehicleApi, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          ...vehicle,
          color: !vehicle.color ? '#000000' : parseColor(vehicle.color).hex,
          created_at: moment().format(),
          modified_at: moment().format(),
          simulation: urls.simulationRef(simulationId),
          id: undefined,
        }),
      });

      if (!response.ok) {
        throw new Error(response.status);
      }

      const responseJSON = await response.json();
      return responseJSON;
    }
  );

export const updateVehicleInSimulation = async (simulationId, vehicle) =>
  D2.A.FUNCTION(
    'updateVehicleInSimulation',
    { simulationId, vehicle },
    async () => {
      const response = await fetchData(urls.vehicleRef(vehicle.id), {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(
          removeInternalFieldsFromObject({
            ...vehicle,
            simulation: `/api/v2/simulation/${simulationId}`,
            color: normalizeVehicleColorForExport(vehicle),
            route: undefined,
            server_ts: undefined,
            modified_at: undefined,
            routing_engine: undefined,
          })
        ),
      });

      if (!response.ok) {
        throw new Error(response.status);
      }

      const responseJSON = await response.json();
      return responseJSON;
    }
  );

export const deleteVehicleFromSimulation = async (id) => {
  const response = await fetchData(urls.vehicleRef(id), {
    method: 'DELETE',
    headers: getHeaders(),
  });
  if (!response.ok) {
    throw new Error(response.status);
  }

  return null;
};
// Unassign all orders from a vehicle
export const unassignAllOrders = async ({ commuteOffer, id }) => {
  const bookingUid = [];
  const breakUid = [];

  const originalNodes = commuteOffer.stateless_api_request_data.nodes.reduce(
    (memo, node) => {
      if (node?.$assigned_vehicle_id === id) {
        if (node?.booking_uid !== null) {
          bookingUid.push(node?.booking_uid);
        } else {
          // break point
          breakUid.push(node?.uid);
        }
      }
      return {
        ...memo,
        [node.uid]: removeInternalFieldsFromObject(node),
      };
    },
    {}
  );

  const updatedNodes = Object.values(originalNodes).reduce((memo, node) => {
    if (
      bookingUid.indexOf(node.booking_uid) > -1 ||
      breakUid.indexOf(node.uid) > -1
    ) {
      return {
        ...memo,
        [node.uid]: removeInternalFieldsFromObject({
          ...node,
          assigned_vehicle_id: null,
          assigned_vehicle: null,
          status: 'new',
        }),
      };
    }
    return {
      ...memo,
      [node.uid]: { ...node },
    };
  }, {});

  const nodesRequest = makeBulkUpdateRequest(
    updatedNodes,
    originalNodes,
    'NODE'
  );  

  const originalBookings = Object.values(
    commuteOffer.stateless_api_request_data.bookings
  ).reduce(
    (memo, booking) => ({
      ...memo,
      [booking.uid]: removeInternalFieldsFromObject(booking),
    }),
    {}
  );

  const updatedBookings = Object.values(originalBookings).reduce(
    (memo, booking) => {
      if (-1 < bookingUid.indexOf(booking.uid)) {
        return {
          ...memo,
          [booking.uid]: {
            ...booking,
            state: 'prepared',
          },
        };
      }

      return {
        ...memo,
        [booking.uid]: { ...booking },
      };
    },
    {}
  );

  const bookingsRequest = makeBulkUpdateRequest(
    updatedBookings,
    originalBookings,
    'BOOKING'
  );  

  if (nodesRequest.objects.length > 0) {
    const nodesResponse = await fetchData(urls.simulationNodeAPI, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(nodesRequest),
      maxAttempts: 1,
    });

    if (!nodesResponse.ok) {
      throw new Error(nodesResponse.status);
    }
  }

  if (bookingsRequest.objects.length > 0) {
    const bookingsResponse = await fetchData(urls.simulationBookingAPI, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(bookingsRequest),
      maxAttempts: 1,
    });

    if (!bookingsResponse.ok) {
      throw new Error(bookingsResponse.status);
    }
  }
  const updatedNodesArray = Object.values(updatedNodes);

  return { nodes: updatedNodesArray, bookings: updatedBookings };
};

export const getProjectTemplateSimulationInfo = async (project_id) => {
  try {
    const response = await fetchData(urls.projectTemplateSimulationInfo(), {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        project_id,
      }),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    global.geodisc$setWindowLocation(ACCESS_DENIED_URL);
    throw error;
  }
};

export const getProjectServiceSimulation = async (project_id, start_time) => {
  const selectServiceDateFormatUTC = moment(start_time).tz('UTC').toISOString();

  const response = await fetchData(
    `${urls.simulationApi}?project=${project_id}&start_time__gte=${selectServiceDateFormatUTC}&start_time__lte=${selectServiceDateFormatUTC}`,
    {
      method: 'GET',
      headers: getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  if (responseJSON.objects.length) {
    return responseJSON.objects[0];
  }
};

export const getProjectTemplateInfo = async (project_id) => {
  try {
    const response = await fetchData(urls.projectTemplateSimulationInfo(), {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        project_id,
      }),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const { simulation_id: simulationId } = await response.json();

    const templateDataResponse = await Promise.all([
      fetchData(urls.simulationRef(simulationId), {
        method: 'GET',
        headers: getHeaders(),
      }),
      fetchData(urls.vehiclesForSimulation(simulationId), {
        method: 'GET',
        headers: getHeaders(),
      }),
    ]);

    const { modified_at: lastUpdateTripTemplate } =
      await templateDataResponse[0].json();
    const { objects: vehicles } = await templateDataResponse[1].json();
    const lastUpdateFleetTemplate = (vehicles || [])
      .map(vehicle => vehicle.modified_at)
      .sort()
      .slice(-1)[0];
    return { lastUpdateTripTemplate, lastUpdateFleetTemplate };
  } catch (error) {
    global.geodisc$setWindowLocation(ACCESS_DENIED_URL);
    throw error;
  }
};

export const createProjectServiceSimulation = async (
  project_id,
  start_time
) => {
  const response = await fetchData(urls.projectServiceSimulationInfo(), {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      project_id,
      start_time,
    }),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();

  return responseJSON;
};

export const fetchSimulationProcessor = async (processor_id) => {
  const response = await fetchData(urls.simulationProcessor(processor_id), {
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();
  return responseJSON;
};

export const waitSimulationProcessor = async (processor_id) => {
  if (!processor_id) {
    return null;
  }
  // eslint-disable-next-line no-constant-condition
  while (42) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000);
    // eslint-disable-next-line no-await-in-loop
    const processorInfo = await fetchSimulationProcessor(processor_id);
    if (processorInfo.state === 'completed') {
      // eslint-disable-next-line no-await-in-loop
      // await sleep(1000);
      return processorInfo;
    }
  }
};

export const fetchSimulationUncalculatedBookings = async (simulation_id) => {
  const response = await fetchData(
    urls.simulationUncalculatedBookings(simulation_id),
    {
      headers: getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseJSON = await response.json();
  return responseJSON;
};

export const waitSimulationUncalculatedBookings = async (simulation_id) => {
  if (!simulation_id) {
    return null;
  }
  // eslint-disable-next-line no-constant-condition
  while (42) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000);
    // eslint-disable-next-line no-await-in-loop
    const bookingsInfo = await fetchSimulationUncalculatedBookings(
      simulation_id
    );
    if (bookingsInfo.objects.length === 0) {
      // eslint-disable-next-line no-await-in-loop
      // await sleep(1000);
      return bookingsInfo;
    }
  }
};

export const updateSimulationSettings = async ({ id, data }) => {
  const { id: simulationId, ...rest } = data;
  const logisticSettings = {
    data: {
      logistics_api_settings: {
        ...rest,
      },
    },
  };
  try {
    const response = await fetchData(urls.simulation(id), {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(logisticSettings),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const updateProject = async ({ data }) => {
  try {
    const response = await fetchData(urls.project(data?.id), {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getProject = async ({ data }) => {
  try {
    const response = await fetchData(urls.project(data?.id), {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const invalidateBooking = async ({ originalOffer, id, booking_uid }) =>
  D2.A.FUNCTION(
    'invalidateBooking',
    { originalOffer, id, booking_uid },
    async ({ $D2 }) => {
      const originalNodes =
        originalOffer.stateless_api_request_data.nodes.reduce(
          (memo, node) => ({
            ...memo,
            [node.uid]: removeInternalFieldsFromObject(node),
          }),
          {}
        );

      const updatedNodes = Object.values(originalNodes).reduce((memo, node) => {
        // filter start/end location node
        if (node.partial_route_index === 1 || node.partial_route_index === -1) {
          return memo;
        }
        if (node?.booking_uid === booking_uid) {
          return {
            ...memo,
            [node.uid]: {
              ...node,
              booking: null,
              booking_uid: null,
              assigned_vehicle: null,
            },
          };
        }
        return {
          ...memo,
          [node.uid]: node,
        };
      }, {});

      const nodesRequest = makeBulkUpdateRequest(
        updatedNodes,
        originalNodes,
        'NODE'
      );

      const originalBookings = Object.values(
        originalOffer.stateless_api_request_data.bookings
      ).reduce(
        (memo, booking) => ({
          ...memo,
          [booking.uid]: removeInternalFieldsFromObject(booking),
        }),
        {}
      );

      const updatedBookings = Object.values(originalBookings).reduce(
        (memo, booking) => {
          if (booking.uid === booking_uid) {
            return {
              ...memo,
              [booking.uid]: { ...booking, is_invalidated: true },
            };
          }
          return {
            ...memo,
            [booking.uid]: booking,
          };
        },
        {}
      );

      const bookingsRequest = makeBulkUpdateRequest(
        updatedBookings,
        originalBookings,
        'BOOKING'
      );

      if (
        nodesRequest.objects.length > 0 ||
        nodesRequest.deleted_objects.length > 0
      ) {
        const nodesResponse = await fetchData(urls.simulationNodeAPI, {
          method: 'PATCH',
          headers: getHeaders(),
          body: JSON.stringify(nodesRequest),
          maxAttempts: 1,
        });

        if (!nodesResponse.ok) {
          throw new Error(nodesResponse.status);
        }
      }

      if (
        bookingsRequest.objects.length > 0 ||
        bookingsRequest.deleted_objects.length > 0
      ) {
        const bookingsResponse = await fetchData(urls.simulationBookingAPI, {
          method: 'PATCH',
          headers: getHeaders(),
          body: JSON.stringify(bookingsRequest),
          maxAttempts: 1,
        });

        if (!bookingsResponse.ok) {
          throw new Error(bookingsResponse.status);
        }
      }
    }
  );

export const getGeocodingResult = async (
  address,
  geocoder,
  language,
  country
) => {
  try {
    const response = await fetchData(urls.geocodingSearch, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        geocoder: geocoder,
        language: language,
        country: country,
        queries: [address],
      }),
    });
    const jsonResponse = await response.json();
    const lat = jsonResponse?.result[address][0]?.lat;
    const lon = jsonResponse?.result[address][0]?.lon;

    return {
      longitude: lon,
      latitude: lat,
    };
  } catch (e) {
    throw new Error(e);
  }
};

export const getAutocompleteResults = async (
  searchTerm,
  geocoder,
  language,
  country
) => {
  try {
    const response = await fetchData(urls.autocomplete, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        geocoder: geocoder,
        language: language,
        queries: [searchTerm],
        country: country,
      }),
    });
    const jsonResponse = await response.json();
    return jsonResponse?.result?.[searchTerm];
  } catch (e) {
    throw new Error(e);
  }
};

export const reverseGeocodeAddress = async (
  queries,
  geocoder,
  language,
  country
) => {
  try {
    const response = await fetchData(urls.reverseGeocoding, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        geocoder: geocoder,
        language: language,
        country: country,
        queries: queries,
      }),
    });
    const jsonResponse = await response.json();

    const address = jsonResponse.result[0][1][1]?.address;
    return address;
  } catch (e) {
    throw new Error(e);
  }
};
