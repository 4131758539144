import debug from 'debug';

/**
 * This function creates data registry
 * and it allows user to push new records increase the number of records and sort data;
 * @returns chainable object that controls the request data
 */
function createRegistry() {
  return {
    data: new Map(),

    push(ident, value) {
      const time = window.performance.now();
      const identArray = this.data.get(ident) || [];
      this.data.set(ident, identArray);
      identArray.push({
        ...value,
        time,
        diff: identArray.length
          ? time - identArray[identArray.length - 1].time
          : null,
      });
      return this;
    },

    inc(ident) {
      const value = this.data.get(ident) || 0;
      this.data.set(ident, value + 1);
      return this;
    },

    sort() {
      return new Map([...this.data].sort());
    },

    reset() {
      const data = this.data;
      this.data = new Map();
      return new Map([...data].sort());
    },
  };
}

global.COUNTERS = global.COUNTERS || createRegistry();

global.TIMINGS = global.TIMINGS || createRegistry();

global.METHODS = global.METHODS || createRegistry();

global.MESSAGES = global.MESSAGES || [];
/**
 *
 * @param {string} namespace use as an identifier
 * @returns {object} expose all the functionalities
 */
const createTracer = (namespace) => {
  let COPY = o => o;

  if (global.GEODISC_DEBUG_ENABLED) {
    COPY = o => JSON.parse(JSON.stringify(o));
  }

  let DEBUG = debug(namespace);

  let TRACE = (fn) => {
    return fn(debug(namespace));
  };

  let COUNTER = () => {};

  if (global.GEODISC_COUNTERS_ENABLED) {
    COUNTER = (name) => {
      const ident = `${namespace}:${name}`;
      global.COUNTERS.inc(ident);
    };
  }

  const EVENT = (name) => {
    DEBUG(name);
    COUNTER(name);
  };

  const profilerStart = () => ({ sTime: window.performance.now() });
  /**
   * This function calculates the execution time of the given function
   * @param {sTime, type, name} param0
   * sTime - Start time
   * eTime - End time
   * pTime - Passed time/ Time difference
   * @returns
   */
  const profilerEnd = ({ sTime, type, name }) => {
    const eTime = window.performance.now();
    const pTime = eTime - sTime;
    const ident = `${namespace}:${name}`;

    let item = global.TIMINGS.data.get(ident);
    if (typeof item === 'undefined') {
      item = {
        total: pTime,
        avg: pTime,
        min: pTime,
        max: pTime,
        timings: [0],
        type,
      };
      global.TIMINGS.data.set(ident, item);
    } else {
      item.total += pTime;
      item.avg = item.avg ? (item.avg + pTime) / 2 : pTime;
      item.min = item.min > pTime ? pTime : item.min;
      item.max = item.max < pTime ? pTime : item.max;
    }
    return { sTime, eTime, pTime, ident };
  };

  const PROFILER = global.GEODISC_PROFILER_ENABLED
    ? (name, fn, withProfiningInfo = false) => {
        const { sTime } = profilerStart();

        const result = fn();

        const timings = profilerEnd({ sTime, type: 's', name });

        if (withProfiningInfo) {
          return { result, timings };
        }

        return result;
      }
    : (name, fn) => fn();

  const C_PROFILER = global.GEODISC_PROFILER_ENABLED
    ? (name, fn, withProfiningInfo = false) => {
        const { sTime } = profilerStart();

        const result = fn();

        const timings = profilerEnd({ sTime, type: 's', name });
        const { pTime, ident } = timings;

        // eslint-disable-next-line
        console.log(`*** ${ident}:Time`, pTime);

        if (withProfiningInfo) {
          return { result, timings };
        }

        return result;
      }
    : (name, fn) => fn();

  const PROFILER_ASYNC = global.GEODISC_PROFILER_ENABLED
    ? async (name, fn) => {
        const { sTime } = profilerStart();

        const result = await fn();

        profilerEnd({ sTime, type: 'a', name });

        return result;
      }
    : async (name, opts, fn) => fn();

  const C_PROFILER_ASYNC = global.GEODISC_PROFILER_ENABLED
    ? async (name, fn) => {
        const { sTime } = profilerStart();

        const result = await fn();

        const { pTime, ident } = profilerEnd({ sTime, type: 'a', name });

        // eslint-disable-next-line
        console.log(`*** ${ident}:Time`, pTime);

        return result;
      }
    : async (name, opts, fn) => fn();

  let METHOD = () => {};

  const $V = (name, opts, value) => {
    METHOD(name, { ...opts, value });
    return value;
  };

  if (global.GEODISC_DEBUG_ENABLED) {
    METHOD = (name, payload, category) => {
      const $name = Array.isArray(name) ? name.join(':') : name.toString();
      const ident = `${namespace}:${$name}`;

      COUNTER(ident);

      const data = typeof payload === 'function' ? payload() : payload;

      const stack = (() => {
        const e = new Error();
        return e.stack;
      })()
        .split('\n')
        .slice(1);

      const categoryName = category || '_';

      global.METHODS.push(categoryName, {
        ___i: ident,
        ___s: stack,
        data,
      });

      global.METHODS.push(ident, {
        ___s: stack,
        data,
      });
    };
  }

  const MESSAGE = (name, payload) => {
    const ident = `${namespace}:${name}`;

    const data = typeof payload === 'function' ? payload() : payload;

    global.MESSAGES.push({
      name: ident,
      data,
    });
  };

  const REDUX_EVENT_HANDLER2 = global.GEODISC_DEBUG_ENABLED
    ? (type, payload, state, fn) => {
        const name = `reducer:${type}`;
        COUNTER(name);
        [
          { ns: '_:redux', nm: `${namespace}:${type}` },
          { ns: namespace, nm: name },
        ].map(({ ns, nm }) => debug(ns)(nm, payload));
        ['_:redux', undefined].map(x =>
          METHOD(
            `${name}:Request`,
            {
              payload,
              state,
              $fn: { state: () => state.toJS() },
            },
            x
          )
        );
        try {
          const value = PROFILER(`${name}`, () =>
            fn({
              $D2: createTracer([namespace, name].join(':')),
            })
          );
          ['_:redux', undefined].map(x =>
            METHOD(
              `${name}:Success`,
              {
                result: value,
                payload,
                state,
                $fn: { result: () => value.toJS(), state: () => state.toJS() },
              },
              x
            )
          );
          return value;
        } catch (error) {
          ['_:redux', undefined].map(x =>
            METHOD(
              `${name}:Failure`,
              {
                error,
                payload,
                state,
                $fn: { state: () => state.toJS() },
              },
              x
            )
          );
          METHOD(name, { error, payload }, '_:error');
          // eslint-disable-next-line
          console.log(error);
          throw error;
        }
      }
    : (type, payload, state, fn) =>
        fn({
          $D2: createTracer([namespace, 'reducer', type].join(':')),
        });

  let REDUX_EVENT_HANDLER = () => {};

  if (global.GEODISC_DEBUG_ENABLED) {
    REDUX_EVENT_HANDLER = (type, payload) => {
      const name = `reducer:${type}`;
      COUNTER(name);
      [
        { ns: '_:redux', nm: `${namespace}:${type}` },
        { ns: namespace, nm: name },
      ].map(({ ns, nm }) => debug(ns)(nm, payload));
      ['_:redux', undefined].map(x => METHOD(name, payload, x));
    };
  }

  const FUNCTION = global.GEODISC_DEBUG_ENABLED
    ? (name, opts, fn) => {
        COUNTER(name);
        METHOD(`${name}:Request`, opts);
        try {
          const value = PROFILER(name, () =>
            fn({
              $D2: createTracer([namespace, name].join(':')),
            })
          );
          METHOD(`${name}:Success`, { ...opts, result: value });
          return value;
        } catch (error) {
          METHOD(`${name}:Failure`, { ...opts, error });
          METHOD(name, { ...opts, error }, '_:error');
          // eslint-disable-next-line
          console.log(error);
          throw error;
        }
      }
    : (name, opts, fn) =>
        PROFILER(name, () =>
          fn({
            $D2: createTracer([namespace, name].join(':')),
          })
        );

  const C_FUNCTION = global.GEODISC_DEBUG_ENABLED
    ? (name, opts, fn) => {
        COUNTER(name);
        METHOD(`${name}:Request`, opts);
        // eslint-disable-next-line
        console.log(`*** ${namespace}:${name}:Request`, opts);
        try {
          const value = C_PROFILER(name, () =>
            fn({
              $D2: createTracer([namespace, name].join(':')),
            })
          );
          METHOD(`${name}:Success`, { ...opts, result: value });
          // eslint-disable-next-line
          console.log(`*** ${namespace}:${name}:Success`, {
            ...opts,
            result: value,
          });
          return value;
        } catch (error) {
          METHOD(`${name}:Failure`, { ...opts, error });
          // eslint-disable-next-line
          console.log(`*** ${namespace}:${name}:Failure`, { ...opts, error });
          METHOD(name, { ...opts, error }, '_:error');
          // eslint-disable-next-line
          console.log(error);
          throw error;
        }
      }
    : (name, opts, fn) =>
        C_PROFILER(name, () =>
          fn({
            $D2: createTracer([namespace, name].join(':')),
          })
        );

  const FUNCTION_ASYNC = global.GEODISC_DEBUG_ENABLED
    ? async (name, opts, fn) => {
        COUNTER(name);
        METHOD(`${name}:Request`, opts);
        try {
          const value = await PROFILER_ASYNC(name, async () =>
            fn({
              $D2: createTracer([namespace, name].join(':')),
            })
          );
          METHOD(`${name}:Success`, { ...opts, result: value });
          return value;
        } catch (error) {
          METHOD(`${name}:Failure`, { ...opts, error });
          METHOD(name, { ...opts, error }, '_:error');
          // eslint-disable-next-line
          console.log(error);
          throw error;
        }
      }
    : async (name, opts, fn) =>
        PROFILER_ASYNC(name, async () =>
          fn({
            $D2: createTracer([namespace, name].join(':')),
          })
        );

  const C_FUNCTION_ASYNC = global.GEODISC_DEBUG_ENABLED
    ? async (name, opts, fn) => {
        COUNTER(name);
        METHOD(`${name}:Request`, opts);
        // eslint-disable-next-line
        console.log(`*** ${namespace}:${name}:Request`, opts);
        try {
          const value = await C_PROFILER_ASYNC(name, async () =>
            fn({
              $D2: createTracer([namespace, name].join(':')),
            })
          );
          METHOD(`${name}:Success`, { ...opts, result: value });
          // eslint-disable-next-line
          console.log(`*** ${namespace}:${name}:Success`, {
            ...opts,
            result: value,
          });
          return value;
        } catch (error) {
          METHOD(`${name}:Failure`, { ...opts, error });
          // eslint-disable-next-line
          console.log(`*** ${namespace}:${name}:Failure`, { ...opts, error });
          METHOD(name, { ...opts, error }, '_:error');
          // eslint-disable-next-line
          console.log(error);
          throw error;
        }
      }
    : async (name, opts, fn) =>
        C_PROFILER_ASYNC(name, async () =>
          fn({
            $D2: createTracer([namespace, name].join(':')),
          })
        );

  const $FN = (fn, name = '$FN') => {
    const func = (...options) =>
      FUNCTION(name, { options }, () => {
        return fn(...options);
      });
    return func;
  };

  const N = name => createTracer([namespace, name].join(':'));

  const result = {
    CONTEXT: {
      $origin: namespace,
    },
    COPY, // is not using anywhere
    DEBUG, // returns decorated `console.error` using `debug npm package`
    TRACE,
    COUNTER,
    EVENT,
    PROFILER,
    PROFILER_ASYNC,
    METHOD,
    MESSAGE,
    FUNCTION,
    FUNCTION_ASYNC,
    REDUX_EVENT_HANDLER,
    REDUX_EVENT_HANDLER2,
    N,
    S: {
      COPY,
      DEBUG,
      TRACE,
      COUNTER,
      EVENT,
      PROFILER,
      METHOD,
      MESSAGE,
      FUNCTION,
      SELECTOR: FUNCTION,
      REDUX_EVENT_HANDLER,
      REDUX_EVENT_HANDLER2,
      $FN,
      FN: (name, params, fn) => $FN(fn, name, params),
      $V,
      V: $V,
      N,
      INFO: METHOD,
      C: {
        FUNCTION: C_FUNCTION,
        SELECTOR: C_FUNCTION,
      },
    },
    A: {
      FUNCTION: FUNCTION_ASYNC,
      PROFILER: PROFILER_ASYNC,
      C: {
        FUNCTION: C_FUNCTION_ASYNC,
      },
    },
  };
  return result;
};

// eslint-disable-next-line no-multi-assign
global.R = global.MR = () => {
  global.TIMINGS.reset();
  global.COUNTERS.reset();
  return global.METHODS.reset();
};

// eslint-disable-next-line no-multi-assign
global.M = global.MS = () => {
  return global.METHODS.sort();
};

// eslint-disable-next-line no-multi-assign
global.PR = global.TR = () => {
  const sorted = global.T();
  global.TIMINGS.reset();
  return sorted;
};

// eslint-disable-next-line no-multi-assign
global.P = global.T = () => {
  const sorted = ['s', 'a'].map((type) => {
    const data = global.TIMINGS.data;
    return [...data.keys()]
      .filter((key) => {
        const record = data.get(key);
        return record.type === type;
      })
      .map((key) => {
        const record = data.get(key);
        return {
          t: parseFloat(record.total.toFixed(2)),
          c: record.timings.length,
          n: key,
          o: record,
        };
      })
      .sort((a, b) => b.t - a.t);
  });
  return sorted;
};

// eslint-disable-next-line no-multi-assign
global.CR = () => {
  return global.COUNTERS.reset();
};

/**
 * sort the counter
 */
// eslint-disable-next-line no-multi-assign
global.C = global.CS = () => {
  return global.COUNTERS.sort();
};

export default createTracer;
