import LegacyValidator from './Validators/Legacy';
import TransitstopsValidator from './Validators/Transitstops';
import AssignmentOrderValidator from './Validators/AssignmentOrder';
import RequestResultOrders from './Validators/RequestResultOrders';

const validators = [
  LegacyValidator,
  TransitstopsValidator,
  AssignmentOrderValidator,
  RequestResultOrders,
];

export default validators;
